import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import Helmet from "react-helmet"
import _ from "underscore"
import styled from "styled-components"
import { getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/styledbackgroundsection"
import BackgroundVideoSection from "../components/backgroundvideosection"
import "./directorDetail.sass"
import slugify from "slugify"

const H1 = styled.h1`
  line-height: 6rem;
  font-size: 6rem;
  font-weight: bold;

  @media (max-width: 400px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`

const DirectorDetail = ({ data }) => {
  const director = data.contentfulDirector
  const firstFilm = director.films[0]
  const [video, setVideo] = useState()

  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0)
    }
    const handleResize = () => {
      let vh = window.innerHeight * 0.01
      const mainVideo = document.querySelector(".main-video")
      if (mainVideo) {
        mainVideo.style.setProperty("--vh", `${vh}px`)
      }
    }
    handleResize()
    window.addEventListener("resize", _.debounce(handleResize, 100))
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 100))
    }
  })

  return (
    <Layout region={data.contentfulRegion.slug} video={video} close={() => setVideo()} list={director.films}>
      <SEO title={director.name} description={director.metaDescription?.metaDescription}/>
      <Helmet htmlAttributes={{ class: "details" }} />
      <div className="details-wrapper">
        <div
          role="button"
          className="main-video"
          onClick={() => {
            navigate(
              `${slugify(firstFilm.title, {
                lower: true,
                strict: true
              })}`
            )
          }}
        >
          <BackgroundVideoSection
            videoUrl={firstFilm.shortVideo}
            videoPoster={firstFilm.cover.localFile.url}
          >
            <H1>{director.name}</H1>
            <h4>
              <span>{firstFilm.brand}</span>— {firstFilm.title}
            </h4>
          </BackgroundVideoSection>
        </div>
        <div className="video-list">
          {director.films.slice(1).map(film => {
            return (
              <StyledBackgroundSection
                key={film.id}
                image={getImage(film.cover.localFile)}
              >
                <div
                  role="button"
                  tabIndex={film.id}
                  className="thumbnail-video"
                  onClick={event => {
                    navigate(
                      `${slugify(film.title, {
                        lower: true,
                        strict: true
                      })}`
                    )
                  }}
                >
                  <h4>
                    <span>{film.brand}</span>
                    <br />
                    <span
                      style={{ fontWeight: `normal`, lineHeight: `1.2rem` }}
                    >
                      {film.title}
                    </span>
                  </h4>
                </div>
              </StyledBackgroundSection>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DirectorDetail($regionId: String!, $directorId: String!) {
    contentfulRegion(id: { eq: $regionId }) {
      slug
    }
    contentfulDirector(id: { eq: $directorId }) {
      id
      name
      metaDescription {
        metaDescription
      }
      films {
        id
        brand
        title
        url
        shortVideo
        director {
          id
          name
        }
        cover {
          id
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
export default DirectorDetail

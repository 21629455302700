import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className, children, image }) => {
  if (image) {
    const bgImage = convertToBgImage(image)
    return (
      <BackgroundImage
        Tag="section"
        className={'commercial-background'}
        {...bgImage}
      >
        {children}
      </BackgroundImage>
    )
  } else {
    return <section className={'commercial-background'}>{children}</section>
  }
}

BackgroundSection.propTypes = {

}

export default BackgroundSection
